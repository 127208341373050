
































import { computed, defineComponent } from "@vue/composition-api";
import { translate } from "@/plugins/multi-language";

export default defineComponent({
    name: "VideoTutorial",
    setup() {
        const source = computed(() => {
            const value = translate('welcome.videoSrc') as string

            if (!value || value === 'welcome.videoSrc') return 'https://youtu.be/embed/BYjPwRMVF7Y?rel=0'

            return value
        })

        return {
            source
        }
    }
})
